import {
  IconCapitalMarkets,
  IconCustodyAndAdmin,
  IconPortfolio,
  IconRegulatory,
} from '@shadow/shadow-components/svgs';
import React, { useCallback, useMemo } from 'react';
import modulesRocket from '../../assets/etf-rocket-for-modules.svg';
import { useMobileBreakpoint } from '../../hooks/use-mobile-breakpoint';
import { AnimatedDiv, TAnimation } from '../animated-div';
import { Card } from './card';
import { CardGridBackground } from './card-grid-background';

const items = [
  <Card className="bg-pink-linear-gradient" title={'Regulatory'}>
    <IconRegulatory variant="marketing-app" />
  </Card>,
  <Card className="bg-blue-linear-gradient" title={'Custody & Administration Integration'}>
    <IconCustodyAndAdmin variant="marketing-app" />
  </Card>,
  <Card className="bg-teal-linear-gradient" title={'Portfolio Implementation'}>
    <IconPortfolio variant="marketing-app" />
  </Card>,
  <Card className="bg-green-linear-gradient" title={'Capital Markets'}>
    <IconCapitalMarkets variant="marketing-app" />
  </Card>,
];

export const CardGrid: React.FC = () => {
  const mobile = useMobileBreakpoint();

  // On mobile - Cards translate into screen from either side, one by one
  // On desktop - Cards translate up into place, one by one
  const generateCardAnimation = useCallback(
    (position: number): TAnimation => {
      const sign = position % 2 === 1 ? -1 : 1;

      const offscreen = mobile ? { x: sign * 40 } : { y: 40 * position };
      const onscreen = mobile ? { x: 0 } : { y: 0 };
      return {
        offscreen: {
          ...offscreen,
          opacity: 0,
        },
        onscreen: {
          ...onscreen,
          opacity: 1,
          transition: {
            bounce: 0.3,
            delay: position * 0.2, // Animation of next card delayed 0.2s
            duration: 1,
            type: 'spring',
          },
        },
      };
    },
    [mobile]
  );

  const rocketAnimation = useMemo((): TAnimation => {
    return {
      offscreen: {
        x: -1000, // Animation begins 1000px to the left
      },
      onscreen: {
        x: 25,
        transition: {
          delay: 0.1,
          duration: 1,
          ease: ['easeOut'],
        },
      },
    };
  }, []);
  return (
    <div className="flex justify-center items-center relative w-full">
      {/* Rocket launches into place from left side of screen just before card animations begin*/}
      <div className="self-center opacity-100 max-w-[1600px] w-screen absolute z-10 mt-[-7rem] 2xl:mt-[-6rem] invisible lg:visible">
        <AnimatedDiv animation={rocketAnimation} once={true}>
          <img src={modulesRocket} alt="modules-rocket" />
        </AnimatedDiv>
      </div>
      <CardGridBackground />
      {/* Cards fade in and translated up into place from left to right */}
      <div className="relative z-20 grid grid-cols-2 lg:grid-cols-4 w-full gap-4 px-8 mt-16 sm:my-40 lg:px-20 xl:px-56">
        {items.map((item, idx) => (
          <AnimatedDiv
            key={idx}
            animation={generateCardAnimation(idx + 1)}
            viewportAmount={0.25}
            once={true}
          >
            {item}
          </AnimatedDiv>
        ))}
      </div>
    </div>
  );
};
