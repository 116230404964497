import React, { useCallback } from 'react';
import { AnimatedDiv, TAnimation } from '../animated-div';

interface ICubeProps {
  alt: string;
  className: string;
  index?: number;
  src: string;
}

export const Cube: React.FC<ICubeProps> = ({ alt, className, index, src }) => {
  const generateCubeAnimation = useCallback((index: number): TAnimation => {
    return {
      offscreen: {
        opacity: 0,
        y: -100,
      },
      onscreen: {
        opacity: 1,
        y: 0,
        transition: {
          bounce: 0.5,
          delay: 0.1 + index * 0.25,
          duration: 1,
          type: 'spring',
        },
      },
    };
  }, []);

  return (
    <div className={className}>
      <AnimatedDiv
        animation={generateCubeAnimation((index ?? 0) + 1)}
        viewportAmount={0.25}
        once={true}
      >
        <img src={src} alt={alt} />
      </AnimatedDiv>
    </div>
  );
};
