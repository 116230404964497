import React from 'react';
import { Link } from 'react-scroll';

interface IButtonProps {
  label: string;
  onClick?: (e: any) => void;
  clickToScroll?: boolean;
  scrollToElementId?: string;
}

export const HeroButton: React.FC<IButtonProps> = ({
  label,
  onClick,
  clickToScroll = false,
  scrollToElementId,
}) => {
  const buttonClassName =
    'flex flex-row justify-center items-center bg-transparent hover:bg-[#ffffff1a] focus:bg-[#ffffff33] focus-visible:bg-[#ffffff33] hover:cursor-pointer border border-white leading-6 text-center py-2 px-6 box-border rounded-sm font-goldmanSansMedium text-white text-sm md:text-base';
  const clickScrollsToElement = clickToScroll && scrollToElementId;

  const renderButton = (): any => {
    if (clickScrollsToElement) {
      return (
        <Link
          className={buttonClassName}
          to={scrollToElementId}
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          {label}
        </Link>
      );
    }

    return (
      <button className={buttonClassName} onClick={onClick}>
        {label}
      </button>
    );
  };

  return renderButton();
};
