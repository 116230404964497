import React from 'react';

interface ITextBlockProps {
  title: string;
  subtitle: string;
}

export const TextBlock: React.FC<ITextBlockProps> = ({ title, subtitle }) => {
  return (
    <div className="grid grid-cols-1 text-center lg:text-left px-8 md:px-0 min-w-full 2xl:min-w-[340px]">
      <div className="lg:border-t lg:border-solid lg:border-gray-080" />
      <div className="font-basisLight text-white pb-6 lg:pt-9 text-[40px] leading-10 sm:text-5xl sm:leading-[80px] ">
        {title}
      </div>
      <div className="font-goldmanSansLight text-gray-040 min-h-[72px] text-xl leading-6 sm:text-2xl sm:leading-9">
        {subtitle}
      </div>
    </div>
  );
};
//  xl:min-w-[280px] 2xl:min-w-[340px]
