/**
 * NOTE: This file content is forked from UITK source code in order to reduce bundle size.
 * If you need to refer to the source, these are the relevant files:
 *
 * https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/blob/master/components/design-system/common/src/typography.ts
 * https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/blob/master/components/style/common/src/inject-font.ts
 * https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/blob/master/components/icon-font/common/src/cdn-base-url.ts
 * https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/blob/master/components/style/common/src/inject-font.ts 
 * https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/blob/master/components/style/common/src/inject-global.ts
 * https://gitlab.aws.site.gs.com/fp/mwp-ui/gs-ux-uitoolkit/-/blob/master/components/style/common/src/emotion/emotion.ts
 */
import createEmotion, { Emotion } from '@emotion/css/create-instance';

export const fontFamily = {
  goldmanSans: 'Goldman Sans',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansThin: 'Goldman Sans Thin',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansLight: 'Goldman Sans Light',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansItalic: 'Goldman Sans Italic',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansMedium: 'Goldman Sans Medium',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansMediumItalic: 'Goldman Sans Medium Italic',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansBold: 'Goldman Sans Bold',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansBoldItalic: 'Goldman Sans Bold Italic',
    /**
     * @deprecated Use `goldmanSans` and fontWeight instead
     */
    goldmanSansBlack: 'Goldman Sans Black',
    goldmanSansVariable: 'Goldman Sans Variable',
    goldmanSansVariableItalic: 'Goldman Sans Variable Italic',
    mono: 'Roboto Mono',
    robotoMonoItalic: 'Roboto Mono Italic',
    robotoMonoMedium: 'Roboto Mono Medium',
    robotoMonoMediumItalic: 'Roboto Mono Medium Italic',
    sabonRoman: 'Sabon',
    sabonItalic: 'Sabon Italic',
    sabonBold: 'Sabon Bold',
    sabonBoldItalic: 'Sabon Bold Italic',
    basis: 'Basis',
    basisItalic: 'Basis Italic',
    basisBold: 'Basis Bold',
    basisBoldItalic: 'Basis Bold Italic',
    basisLight: 'Basis Light',
    basisLightItalic: 'Basis Light Italic',
    basisMedium: 'Basis Medium',
    basisMediumItalic: 'Basis Medium Italic',
    basisOffWhite: 'Basis Off White',
    basisOffWhiteItalic: 'Basis Off White Italic',
    basisMonoRegular: 'Basis Mono Regular',
    basisMonoMedium: 'Basis Mono Medium',

};

export const fontWeight = {
  thin: 100,
  ultraLight: 200,
  light: 300,
  regular: 400,
  normal: 400,
  medium: 500,
  bold: 700,
  strong: 700,
  black: 900,
};

let hasInjectedIconFonts = false;

export function injectGsdsFonts() {
  if (!hasInjectedIconFonts) {
    getFontFaces().forEach(fontFace => injectFont(fontFace));
    
    hasInjectedIconFonts = true;
  }
}

export interface FontFace {
  family: string;
  systemName?: string;
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | 950 | string;
  style?: 'normal' | 'italic' | 'oblique';
  src: string | FontFaceSrc | (string | FontFaceSrc)[];
}

export interface FontFaceSrc {
  url: string;
  format?: string;
}

export function getFontFaces(): FontFace[] {
  const { goldmanSans, robotoMono, sabon, basis, deprecatedGoldmanSans } = getFontFacesByFamily();

  return [...goldmanSans, ...robotoMono, ...sabon, ...basis, ...deprecatedGoldmanSans];
}

interface DesignSystemFontFaces {
  goldmanSans: FontFace[];
  deprecatedGoldmanSans: FontFace[];
  robotoMono: FontFace[];
  sabon: FontFace[];
  basis: FontFace[];
}

export function getFontFacesByFamily(): DesignSystemFontFaces {
  const cdnBaseUrl = 'https://cdn.gs.com';
  const goldmanSansBaseUrl = `${cdnBaseUrl}/fonts/goldman-sans/v1`;
  const robotoMonoBaseUrl = `${cdnBaseUrl}/fonts/roboto-mono/v1`;
  const sabonBaseUrl = `${cdnBaseUrl}/fonts/sabon/v1`;
  const basisBaseUrl = `${cdnBaseUrl}/fonts/basis/v1`;

  const goldmanSans: FontFace[] = [
      {
          family: fontFamily.goldmanSans,
          weight: 100, // "thin"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-thin.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-thin.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 300, // "light"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-light.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-light.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 400, // "regular"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-regular.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-regular.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 400, // "regular"
          style: 'italic',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 500, // "medium"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-medium.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-medium.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 500, // "medium"
          style: 'italic',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 700, // "bold"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-bold.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-bold.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 700, // "bold"
          style: 'italic',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 900, // "black"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-black.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-black.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansVariable,
          weight: '1 999', // variable font weights are specified as a range
          style: 'normal',
          src: [`${goldmanSansBaseUrl}/goldman-sans-variable.woff2`],
      },
      {
          family: fontFamily.goldmanSansVariableItalic,
          weight: '1 999', // variable font weights are specified as a range
          style: 'normal',
          src: [`${goldmanSansBaseUrl}/goldman-sans-variable-italic.woff2`],
      },
  ];

  const robotoMono: FontFace[] = [
      {
          family: fontFamily.mono,
          weight: 400, // "regular"
          style: 'normal',
          src: [
              `${robotoMonoBaseUrl}/roboto-mono-regular.woff2`,
              `${robotoMonoBaseUrl}/roboto-mono-regular.woff`,
          ],
      },
      {
          family: fontFamily.robotoMonoItalic,
          weight: 400,
          style: 'normal',
          src: [
              `${robotoMonoBaseUrl}/roboto-mono-regular-italic.woff2`,
              `${robotoMonoBaseUrl}/roboto-mono-regular-italic.woff`,
          ],
      },
      {
          family: fontFamily.robotoMonoMedium,
          weight: 400,
          style: 'normal',
          src: [
              `${robotoMonoBaseUrl}/roboto-mono-medium.woff2`,
              `${robotoMonoBaseUrl}/roboto-mono-medium.woff`,
          ],
      },
      {
          family: fontFamily.robotoMonoMediumItalic,
          weight: 400,
          style: 'normal',
          src: [
              `${robotoMonoBaseUrl}/roboto-mono-medium-italic.woff2`,
              `${robotoMonoBaseUrl}/roboto-mono-medium-italic.woff`,
          ],
      },
  ];

  const sabon: FontFace[] = [
      {
          family: fontFamily.sabonRoman,
          weight: 400,
          style: 'normal',
          src: [`${sabonBaseUrl}/sabon.woff2`, `${sabonBaseUrl}/sabon.woff`],
      },
      {
          family: fontFamily.sabonItalic,
          weight: 400,
          style: 'normal',
          src: [`${sabonBaseUrl}/sabon-italic.woff2`, `${sabonBaseUrl}/sabon-italic.woff`],
      },
      {
          family: fontFamily.sabonBold,
          weight: 400,
          style: 'normal',
          src: [`${sabonBaseUrl}/sabon-bold.woff2`, `${sabonBaseUrl}/sabon-bold.woff`],
      },
      {
          family: fontFamily.sabonBoldItalic,
          weight: 400,
          style: 'normal',
          src: [
              `${sabonBaseUrl}/sabon-bold-italic.woff2`,
              `${sabonBaseUrl}/sabon-bold-italic.woff`,
          ],
      },
  ];

  const basis: FontFace[] = [
      {
          family: fontFamily.basis,
          weight: 400,
          style: 'normal',
          src: [`${basisBaseUrl}/basis-regular.woff2`, `${basisBaseUrl}/basis-regular.woff`],
      },
      {
          family: fontFamily.basisItalic,
          weight: 400,
          style: 'normal',
          src: [`${basisBaseUrl}/basis-italic.woff2`, `${basisBaseUrl}/basis-italic.woff`],
      },
      {
          family: fontFamily.basisBold,
          weight: 400,
          style: 'normal',
          src: [`${basisBaseUrl}/basis-bold.woff2`, `${basisBaseUrl}/basis-bold.woff`],
      },
      {
          family: fontFamily.basisBoldItalic,
          weight: 400,
          style: 'normal',
          src: [
              `${basisBaseUrl}/basis-bold-italic.woff2`,
              `${basisBaseUrl}/basis-bold-italic.woff`,
          ],
      },
      {
          family: fontFamily.basisLight,
          weight: 400,
          style: 'normal',
          src: [`${basisBaseUrl}/basis-light.woff2`, `${basisBaseUrl}/basis-light.woff`],
      },
      {
          family: fontFamily.basisLightItalic,
          weight: 400,
          style: 'normal',
          src: [
              `${basisBaseUrl}/basis-light-italic.woff2`,
              `${basisBaseUrl}/basis-light-italic.woff`,
          ],
      },
      {
          family: fontFamily.basisMedium,
          weight: 400,
          style: 'normal',
          src: [`${basisBaseUrl}/basis-medium.woff2`, `${basisBaseUrl}/basis-medium.woff`],
      },
      {
          family: fontFamily.basisMediumItalic,
          weight: 400,
          style: 'normal',
          src: [
              `${basisBaseUrl}/basis-medium-italic.woff2`,
              `${basisBaseUrl}/basis-medium-italic.woff`,
          ],
      },
      {
          family: fontFamily.basisOffWhite,
          weight: 400,
          style: 'normal',
          src: [`${basisBaseUrl}/basis-off-white.woff2`, `${basisBaseUrl}/basis-off-white.woff`],
      },
      {
          family: fontFamily.basisOffWhiteItalic,
          weight: 400,
          style: 'normal',
          src: [
              `${basisBaseUrl}/basis-off-white-italic.woff2`,
              `${basisBaseUrl}/basis-off-white-italic.woff`,
          ],
      },
      {
          family: fontFamily.basisMonoRegular,
          weight: 400,
          style: 'normal',
          src: [
              `${basisBaseUrl}/basis-mono-regular.woff2`,
              `${basisBaseUrl}/basis-mono-regular.woff`,
          ],
      },
      {
          family: fontFamily.basisMonoMedium,
          weight: 400,
          style: 'normal',
          src: [
              `${basisBaseUrl}/basis-mono-medium.woff2`,
              `${basisBaseUrl}/basis-mono-medium.woff`,
          ],
      },
  ];

  const deprecatedGoldmanSans: FontFace[] = [
      {
          family: fontFamily.goldmanSansThin,
          weight: 100, // "thin"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-thin.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-thin.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansLight,
          weight: 300, // "light"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-light.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-light.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSans,
          weight: 400, // "regular"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-regular.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-regular.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansItalic,
          weight: 400, // "regular"
          style: 'italic',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-regular-italic.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansMedium,
          weight: 500, // "medium"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-medium.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-medium.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansMediumItalic,
          weight: 500, // "medium"
          style: 'italic',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-medium-italic.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansBold,
          weight: 700, // "bold"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-bold.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-bold.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansBoldItalic,
          weight: 700, // "bold"
          style: 'italic',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-bold-italic.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansBlack,
          weight: 900, // "black"
          style: 'normal',
          src: [
              `${goldmanSansBaseUrl}/goldman-sans-black.woff2`,
              `${goldmanSansBaseUrl}/goldman-sans-black.woff`,
          ],
      },
      {
          family: fontFamily.goldmanSansVariable,
          weight: '1 999', // variable font weights are specified as a range
          style: 'normal',
          src: [`${goldmanSansBaseUrl}/goldman-sans-variable.woff2`],
      },
      {
          family: fontFamily.goldmanSansVariableItalic,
          weight: '1 999', // variable font weights are specified as a range
          style: 'normal',
          src: [`${goldmanSansBaseUrl}/goldman-sans-variable-italic.woff2`],
      },
    ];

    return { goldmanSans, deprecatedGoldmanSans, robotoMono, sabon, basis };
}

export function injectFont(fontFace: FontFace) {
  const fontFaceSrcs = normalizeFontFaceSrcs(fontFace.src);
  const fontFaceDefinitionStrings = fontFaceSrcs.map(src => {
      return `url("${src.url}")${src.format ? ` format("${src.format}")` : ''}`;
  });
  injectGlobal({
      '@font-face': {
          fontFamily: fontFace.family,
          src: `${fontFaceDefinitionStrings.join(', ')}`,
          fontWeight: fontFace.weight,
          fontStyle: fontFace.style,
          fontDisplay: 'swap',
      },
  });
}

export const injectGlobal: {
  (template: TemplateStringsArray, ...args: any[]): void;
  (...args: any[]): void;
} = getEmotionInstance().injectGlobal;

export function getEmotionInstance(): Emotion {
  const globalKey = '__gs_uitk_emotion_instance';
  let instance = (window as any)[globalKey];
  if (!instance) {
      instance = (window as any)[globalKey] = createEmotionInstance();
  }
  return instance;
}

export function createEmotionInstance({
  container,
}: {
  container?: HTMLElement | undefined;
} = {}) {
  // First look for a <meta> element on the page that might deliver us a CSP
  // nonce to use for the <style> tag that Emotion will generate. This is
  // needed to whitelist our <style> tags so that they are not blocked by the
  // CSP
  const metaEl =
      typeof document !== 'undefined'
          ? document.querySelector('meta[property="csp-nonce"]')
          : null;
  const nonce = metaEl?.getAttribute('content') || undefined;

  const emotion = createEmotion({
      key: 'gs-uitk-c',
      container,

      // In order to whitelist our <style> tag for a content security policy
      // (CSP) which restricts <style> tags by default, a nonce is needed for
      // the <style> tag we'll generate
      nonce,

      // Prepend styles into the <head> rather than append them. This allows
      // users' own CSS classes from .css files to override our components
      // via higher source order when they use the 'className' and 'classes' props
      prepend: true,

      // enable fast mode (i.e. use CSSStyleSheet.insertRule() API vs. adding
      // new <style> tags for each rule)
      speedy: true,
  });

  return emotion;
}


function normalizeFontFaceSrcs(
  src: string | FontFaceSrc | (string | FontFaceSrc)[]
): FontFaceSrc[] {
  if (typeof src === 'string') {
      return [
          {
              url: src,
              format: parseFormat(src),
          },
      ];
  } else if (Array.isArray(src)) {
      return src.map(src => normalizeFontFaceSrcs(src)).reduce((arr, item) => arr.concat(item)); // flatten
  } else {
      return [
          {
              url: src.url,
              format: src.format || parseFormat(src.url),
          },
      ];
  }
}

function parseFormat(url: string): string {
  const extension = url.match(/\.(\w+)$/); // ex: 'GoldmanSans.woff2' -> 'woff2'
  switch (extension && extension[1]) {
      case 'woff':
      case 'woff2':
      case 'svg':
          return extension![1];
      case 'ttf':
          return 'truetype';
      case 'eot':
          return 'embedded-opentype';
      case 'otf':
      case 'otc':
      // case 'ttf': note: opentype was based on its predecessor ttf (truetype) and
      //             sometimes maintains its extension, but we assume ttf to be truetype above
      case 'ttc':
          return 'opentype';
      default:
          return ''; // unknown or couldn't parse
  }
}

