import React from 'react';

export const OurServices: React.FC = () => {
  return (
    <div
      id="services"
      className="flex flex-col relative z-10 w-full px-8 pt-40 sm:px-8 sm:pt-32 sm:pb-0 md:pt-60 lg:pt-40 lg:px-20 xl:px-56"
    >
      <div className="font-basisLight text-white self-center text-3xl mb-12 sm:text-4xl md:text-5xl md:leading-[80px] md:mb-[5rem]">
        Our Services
      </div>

      <div className="text-gray-040 font-goldmanSansLight self-center md:text-xl md:leading-[32px] lg:text-2xl lg:leading-[36px] mb-[2rem] md:mb-[8rem] lg:mx-[7rem]">
        The Goldman Sachs ETF Accelerator business will provide services across fund launch and
        integration into the ETF ecosystem, along with portfolio implementation and capital markets
        solutions.
      </div>

      <div className="grid grid-cols-1 gap-8 md:gap-0 sm:grid-cols-2">
        <div className="flex flex-col justify-self-start gap-7 md:gap-12 lg:gap-11 sm:w-[290px] md:w-[350px] lg:w-[424px] ">
          <div className="font-basisLight text-white text-2xl md:text-[28px] md:leading-[32px] lg:text-[32px] lg:leading-[32px]">
            Launch
          </div>
          <div className="text-gray-040 font-goldmanSansLight md:text-xl md:leading-[32px] lg:text-2xl lg:leading-[36px]">
            Outsourced launch process with complete transparency to the client along the way.
          </div>
        </div>
        <div className="flex flex-col justify-self-start sm:justify-self-end gap-7 md:gap-12 lg:gap-11 sm:w-[290px] md:w-[350px] lg:w-[424px]">
          <div className="font-basisLight text-white text-2xl sm:text-right md:text-[28px] md:leading-[32px] lg:text-[32px] lg:leading-[32px]">
            Ongoing
          </div>
          <div className="text-gray-040 font-goldmanSansLight sm:text-right md:text-xl md:leading-[32px] lg:text-2xl lg:leading-[36px]">
            Ongoing fund services provided by experienced client and portfolio implementation teams.
          </div>
        </div>
      </div>
    </div>
  );
};
