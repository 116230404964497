import React from 'react';
import lisaSteveInterViewJpg from '../assets/lisa-steve-interview.jpg';
import { CardGrid } from '../components/card-grid';
import { CubesGraphic } from '../components/cubes';
import { Footer } from '../components/footer';
import { GetInTouch } from '../components/get-in-touch';
import { HomeHero, HomeHeroBackground } from '../components/hero';
import { OurServices } from '../components/our-services';
import { Quote } from '../components/quote';
import { TextBlockGrid } from '../components/text-block-grid';
import { Video } from '../components/video';

interface IHomeProps {}

export const Home: React.FC<IHomeProps> = () => {
  return (
    <>
      <div className={'flex flex-col'}>
        <HomeHeroBackground />
      </div>
      <div className={'flex flex-col max-w-[1600px] min-w-[300px] w-screen relative'}>
        <HomeHero />
        <OurServices />
      </div>
      <div
        className={
          'flex flex-col max-w-[1600px] min-w-[300px] mb-16 lg:mb-[18rem] w-screen relative'
        }
      >
        <CardGrid />
        <Quote
          quoteText={
            'Many of our clients have told us they believe the next wave of growth is in Active ETFs. They want to be a part of that growth but don’t have the expertise or infrastructure to do so. We are delivering a digital solution that allows us to partner with our clients on that journey.'
          }
          name={'Lisa Mantil'}
          title={'Global Head of Goldman Sachs ETF Accelerator'}
        />
        {
          <div className="flex flex-col relative z-10 w-full px-8 pt-28 sm:px-8 sm:pt-32 sm:pb-0 md:pt-[160px] lg:px-44 xl:px-80 ">
            <Video
              poster={lisaSteveInterViewJpg}
              src={{
                hls: `https://d2wot7r5hbi9xl.cloudfront.net/video/7ff4f249-6992-4f6d-93f5-7bd8c9f5091e/lisa-steve-interview.m3u8`,
                mp4: `https://d2wot7r5hbi9xl.cloudfront.net/video/7ff4f249-6992-4f6d-93f5-7bd8c9f5091e/lisa-steve-interview.mp4`,
              }}
            />
          </div>
        }
        <TextBlockGrid />
      </div>
      <div className={'flex flex-col max-w-[1900px] min-w-[300px] w-full'}>
        <CubesGraphic />
      </div>
      <div className={'flex flex-col max-w-[1600px] min-w-[300px] mb-16 w-screen relative'}>
        <GetInTouch />
        <Footer />
      </div>
    </>
  );
};
