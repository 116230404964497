import { useLayoutEffect, useState } from 'react';

// Will determine if the viewport of the screen is less than provided breakpoint
const isMobileDevice = (breakpoint: number) => {
  return window.innerWidth < breakpoint;
};

export const useMobileBreakpoint = (breakpoint = 1024): boolean => {
  const [mobile, setMobile] = useState(() => isMobileDevice(breakpoint));

  useLayoutEffect(() => {
    const onResize = () => {
      const isMobile = isMobileDevice(breakpoint);
      if (mobile !== isMobile) {
        setMobile(isMobile);
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });

  return mobile;
};
