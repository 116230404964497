import { Header } from '@gs-ux-uitoolkit-react/header';
import { ThemeContainer } from '@gs-ux-uitoolkit-react/theme';
import { shadowDarkTheme } from '@shadow/shadow-components/dark-theme';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.css';

const closeMenu = () => {
  const closeMenu = document.querySelector('[aria-label="Close menu"]') as HTMLElement;
  if (closeMenu) {
    closeMenu.click();
  }
};
export const Navigation = () => {
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hash === '#services') {
      document.getElementById('services')?.scrollIntoView({ behavior: 'smooth' });
    }
    if (hash === '#get-in-touch') {
      document.getElementById('get-in-touch')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [hash]);

  return (
    <ThemeContainer theme={shadowDarkTheme} className="bg-transparent">
      <div className="flex flex-row relative z-20 w-full px-[14px] lg:px-20 lg:pt-16">
        <Header
          brand={{
            appName: 'ETF Accelerator',
            onClick: () => {
              navigate('/home');

              closeMenu();
            },
          }}
          size="lg"
          cta={{
            text: 'Get in touch',
            onClick: () => {
              if (pathname.includes('home')) {
                navigate('/home#get-in-touch');
              } else {
                navigate('/news#get-in-touch');
              }
              if (hash === '#get-in-touch') {
                document.getElementById('get-in-touch')?.scrollIntoView({ behavior: 'smooth' });
              }

              closeMenu();
            },
          }}
          opacityPercent={0}
          position="sticky"
          navigation={{
            menuItemTooltips: true,
            menuItems: [
              {
                text: 'Home',
                tooltip: 'Home',
                id: 'home',
                onClick: () => {
                  navigate('/home');

                  closeMenu();
                },
              },
              {
                text: 'Services',
                tooltip: 'Services',
                id: 'services',
                onClick: () => {
                  navigate('/home#services');

                  if (hash === '#services') {
                    document.getElementById('services')?.scrollIntoView({ behavior: 'smooth' });
                  }
                  closeMenu();
                },
              },
              {
                text: 'News',
                tooltip: 'News',
                id: 'news',
                onClick: () => {
                  navigate('/news');

                  closeMenu();
                },
              },
              {
                text: 'Client login',
                tooltip: 'Client login',
                id: 'client-login',
                onClick: () => {
                  window.open(window.location.origin + '/app', '_blank');
                },
              },
            ],
          }}
        />
      </div>
    </ThemeContainer>
  );
};
