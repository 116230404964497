import React from 'react';
import cube1 from '../../assets/cube1.svg';
import cube2 from '../../assets/cube2.svg';
import cube3 from '../../assets/cube3.svg';
import cubesBackground from '../../assets/cubes-horizon.webp';
import { useMobileBreakpoint } from '../../hooks/use-mobile-breakpoint';
import { Cube } from './cubes';

const cubes = [
  {
    className:
      'opacity-100 w-[116px] h-[116px] absolute z-20 lg:left-[8rem] lg:top-[19.5rem] xl:left-[18rem] xl:top-[18.8rem] 2xl:left-[19rem] 2xl:top-[19.5rem]',
    src: cube1,
    alt: 'cube1',
  },
  {
    className:
      'opacity-100 w-[150px] h-[150px] absolute z-20 lg:left-[30rem] lg:top-[12rem] xl:left-[38rem] xl:top-[12.3rem] 2xl:left-[44rem] 2xl:top-[10.8rem]',
    src: cube2,
    alt: 'cube2',
  },
  {
    className:
      'opacity-100 w-[400px] h-[400px] absolute z-20 lg:left-[46rem] lg:top-[-7.5rem] xl:left-[55rem] xl:top-[-7.5rem] 2xl:left-[64rem] 2xl:top-[-9.4rem]',
    src: cube3,
    alt: 'cube3',
  },
];

export const CubesGraphic: React.FC = () => {
  const mobile = useMobileBreakpoint();

  return mobile ? null : (
    <>
      <div className="flex mb-[24rem] xl:mt-0 2xl:mt-[2rem] relative">
        {cubes.map((curr, index) => (
          <Cube key={index} {...curr} index={index} />
        ))}
      </div>
      <img
        className="self-center opacity-100 absolute z-0 max-w-[1920px]"
        src={cubesBackground}
        alt="cubes-background"
      />
    </>
  );
};
