import React from 'react';

interface IFooterLinkProps {
  children: string;
  href: string;
}

export const FooterLink: React.FC<IFooterLinkProps> = ({ children, href }: IFooterLinkProps) => {
  return (
    <a className="underline" data-track="aa" href={href} rel="noreferrer" target="_blank">
      {children}
    </a>
  );
};
