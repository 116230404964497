import React from 'react';
import { ISvgProps } from './types';

export const IconCapitalMarkets: React.FC<ISvgProps> = ({ variant }) => {
  return variant === 'marketing-app' ? (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0_144_1779" mask="alpha" maskUnits="userSpaceOnUse" x="0" y="0">
        <rect width="128" height="128" fill="#DCE3E8" />
      </mask>
      <g mask="url(#mask0_144_1779)">
        <path d="M75.201 52.1644L126.164 1.2012" stroke="#1D3932" strokeWidth="2" />
        <path
          d="M75.1982 25.7647L75.1982 52.1617L101.595 52.1617"
          stroke="#1D3932"
          strokeWidth="2"
        />
        <path d="M75.2011 75.1957L126.164 126.159" stroke="#1D3932" strokeWidth="2" />
        <path
          d="M75.1982 101.595L75.1982 75.1984L101.595 75.1984"
          stroke="#1D3932"
          strokeWidth="2"
        />
        <path d="M52.1589 52.1645L1.19583 1.20132" stroke="#1D3932" strokeWidth="2" />
        <path
          d="M52.1617 25.7648L52.1617 52.1617L25.7647 52.1617"
          stroke="#1D3932"
          strokeWidth="2"
        />
        <path d="M52.1589 75.1957L1.19583 126.159" stroke="#1D3932" strokeWidth="2" />
        <path
          d="M52.1617 101.595L52.1617 75.1984L25.7647 75.1984"
          stroke="#1D3932"
          strokeWidth="2"
        />
      </g>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <g clipPath="url(#clip0_452_100132)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.99995 7.5H6.28569L0.350342 1.56465L1.05745 0.857544L6.99995 6.80005L6.99995 4.5H7.99995V7.5V8.5H6.99995H3.99995V7.5ZM16 7.5H13.7142L19.6496 1.56465L18.9425 0.857544L13 6.80005L13 4.5H12V7.5V8.5L13 8.5H16V7.5ZM13.7142 13.5H16V12.5H13H12V13.5V16.5H13L13 14.2L18.9425 20.1425L19.6496 19.4353L13.7142 13.5ZM3.99995 13.5H6.28569L0.350342 19.4353L1.05745 20.1425L6.99995 14.2L6.99995 16.5H7.99995V13.5V12.5H6.99995H3.99995V13.5Z"
          fill="#47D580"
        />
      </g>
      <defs>
        <clipPath id="clip0_452_100132">
          <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
