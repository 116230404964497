import { colors } from '@gs-ux-uitoolkit-react/design-system';
import { darkTheme, Theme } from '@gs-ux-uitoolkit-react/theme';

const customBackground = '#16222b';

/**
 * Common colors used across Figma specs:
 * - #1C2B36: shadowDarkTheme.surface.tertiary | colors.gray090
 */

export const shadowDarkTheme: Theme = {
  ...darkTheme,
  surface: {
    ...darkTheme.surface,
    primary: customBackground,
  },
  colorScheme: {
    ...darkTheme.colorScheme,
    header: colors.gray090,
  },
};
