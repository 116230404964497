import React from 'react';
import { ISvgProps } from './types';

export const IconCustodyAndAdmin: React.FC<ISvgProps> = ({ variant }) => {
  return variant === 'marketing-app' ? (
    <svg
      width="128"
      height="128"
      viewBox="0 0 129 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="54" y="53" width="22" height="22" stroke="#1F2937" strokeWidth="2" />
      <path d="M20.3893 108.57L109.484 19.4736" stroke="#1F2937" strokeWidth="2" />
      <path d="M20.3888 19.4741L109.485 108.569" stroke="#1F2937" strokeWidth="2" />
      <rect x="106" y="1" width="22" height="22" rx="11" stroke="#1F2937" strokeWidth="2" />
      <rect x="1.99995" y="105" width="22" height="22" rx="11" stroke="#1F2937" strokeWidth="2" />
      <rect
        x="127"
        y="105"
        width="22"
        height="22"
        rx="11"
        transform="rotate(90 127 105)"
        stroke="#1F2937"
        strokeWidth="2"
      />
      <rect
        x="23"
        y="1.00006"
        width="22"
        height="22"
        rx="11"
        transform="rotate(90 23 1.00006)"
        stroke="#1F2937"
        strokeWidth="2"
      />
    </svg>
  ) : (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6C5.60457 6 6.5 5.10457 6.5 4C6.5 2.89543 5.60457 2 4.5 2C3.39543 2 2.5 2.89543 2.5 4C2.5 5.10457 3.39543 6 4.5 6ZM4.5 7C5.14787 7 5.74778 6.79463 6.23817 6.44544L8.5 8.70728V9V11V11.3589L6.27655 13.5823C5.77923 13.2163 5.16487 13 4.5 13C2.84315 13 1.5 14.3431 1.5 16C1.5 17.6569 2.84315 19 4.5 19C6.15685 19 7.5 17.6569 7.5 16C7.5 15.3691 7.30524 14.7836 6.97255 14.3005L9.2731 12H9.5H11.5H11.7928L14.0546 14.2618C13.7054 14.7522 13.5 15.3521 13.5 16C13.5 17.6569 14.8431 19 16.5 19C18.1569 19 19.5 17.6569 19.5 16C19.5 14.3431 18.1569 13 16.5 13C15.8521 13 15.2521 13.2054 14.7617 13.5546L12.5 11.2929V11V9V8.7731L14.8005 6.47255C15.2836 6.80524 15.8691 7 16.5 7C18.1569 7 19.5 5.65685 19.5 4C19.5 2.34315 18.1569 1 16.5 1C14.8431 1 13.5 2.34315 13.5 4C13.5 4.66487 13.7163 5.27923 14.0823 5.77655L11.8589 8H11.5H9.5H9.20694L6.9453 5.73837C7.29458 5.24794 7.5 4.64796 7.5 4C7.5 2.34315 6.15685 1 4.5 1C2.84315 1 1.5 2.34315 1.5 4C1.5 5.65685 2.84315 7 4.5 7ZM4.5 18C5.60457 18 6.5 17.1046 6.5 16C6.5 14.8954 5.60457 14 4.5 14C3.39543 14 2.5 14.8954 2.5 16C2.5 17.1046 3.39543 18 4.5 18ZM18.5 4C18.5 5.10457 17.6046 6 16.5 6C15.3954 6 14.5 5.10457 14.5 4C14.5 2.89543 15.3954 2 16.5 2C17.6046 2 18.5 2.89543 18.5 4ZM16.5 18C17.6046 18 18.5 17.1046 18.5 16C18.5 14.8954 17.6046 14 16.5 14C15.3954 14 14.5 14.8954 14.5 16C14.5 17.1046 15.3954 18 16.5 18ZM9.5 9H11.5V11H9.5V9Z"
        fill="#2D8DFF"
      />
    </svg>
  );
};
