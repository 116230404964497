export type TShadowEnv =
  | 'LOCAL'
  | 'PREVIEW'
  | 'DEV'
  | 'QA'
  | 'QA_INTERNAL'
  | 'PROD'
  | 'PROD_INTERNAL';

interface IDomainWrapper {
  default: string;
  primaryAlias: string;
  secondaryAliases: string[];
}
export const envToDomains: Partial<Record<TShadowEnv, IDomainWrapper>> = {
  DEV: {
    default: '194371-dev-int.preview.site.gs.com',
    primaryAlias: 'dev-etfaccelerator.site.gs.com',
    secondaryAliases: ['dev-shadow.gs.com', 'dev-etf.gs.com'],
  },
  PROD: {
    default: 'd2bxs1cmdb6l1b.cloudfront.net',
    primaryAlias: 'etfaccelerator.gs.com',
    secondaryAliases: ['etf.gs.com'],
  },
  QA: {
    default: 'd1d3a8j4rsf75h.cloudfront.net',
    primaryAlias: 'qa-etfaccelerator.gs.com',
    secondaryAliases: ['qa-shadow.gs.com', 'qa-etf.gs.com'],
  },
  QA_INTERNAL: {
    default: '196462-qainternal-int.preview.site.gs.com',
    primaryAlias: 'qa-etfaccelerator.site.gs.com',
    secondaryAliases: [],
  },
  PROD_INTERNAL: {
    default: '203539-prodinternal-int.230989.us-east-1.preview.site.gs.com',
    primaryAlias: 'etfaccelerator.site.gs.com',
    secondaryAliases: [],
  },
};

const domainToEnv = Object.entries(envToDomains).reduce<Record<string, TShadowEnv>>(
  (acc, [env, domainWrapper]) => {
    acc[domainWrapper.default] = env as TShadowEnv;
    acc[domainWrapper.primaryAlias] = env as TShadowEnv;
    domainWrapper.secondaryAliases.forEach((secAlias) => {
      acc[secAlias] = env as TShadowEnv;
    });

    return acc;
  },
  {}
);

export const getEnvByHostname = (hostname: string): TShadowEnv => {
  const shadowEnv = domainToEnv[hostname];

  if (shadowEnv) {
    return shadowEnv;
  }

  if (hostname.startsWith('194371-')) {
    return 'PREVIEW';
  }

  return 'LOCAL';
};

export const getCurrentEnv = (): TShadowEnv => {
  const hostname = window.location.hostname;
  return getEnvByHostname(hostname);
};
