import { createLogger } from '../create-logger';

const logger = createLogger('welcome');

export interface IPkgStats {
  name: string;
  version: string;
}

export interface IStyledMessage {
  msg: string;
  style: {
    [rule: string]: string;
  };
}

export const logWelcome = (pkgStats: IPkgStats): void => {
  const checkFlag = String.fromCodePoint(0x1f3c1);
  const nameBadge = String.fromCodePoint(0x1f4db);

  const styledMessages: IStyledMessage[] = [
    {
      msg: 'Welcome to ETF Accelerator',
      style: {
        'font-size': '20px',
        'margin-top': '8px',
        'margin-bottom': '10px',
      },
    },
    {
      msg: `${nameBadge} Name: ${pkgStats.name}`,
      style: {
        padding: '2px 0',
      },
    },
    {
      msg: `${checkFlag} Version: ${pkgStats.version}`,
      style: {
        padding: '2px 0',
        'margin-bottom': '2px',
      },
    },
  ];

  const [message, styles] = buildStyledConsoleMessage(styledMessages);
  console.log(message, ...styles); // eslint-disable-line no-console

  // preserve logger.info() to persist pkg stats in DD
  logger.info(`${JSON.stringify(pkgStats)}`);
};

export const buildStyledConsoleMessage = (
  styledMessages: IStyledMessage[]
): [message: string, styles: string[]] => {
  const message = styledMessages.map((tuple: IStyledMessage) => `%c${tuple.msg}\n`).join('');
  const styles = styledMessages
    .map((tuple: IStyledMessage) => tuple.style)
    .map((style) =>
      Object.entries(style).reduce((rules, [rule, val]) => `${rules}${rule}:${val};`, '')
    );

  return [message, styles];
};
