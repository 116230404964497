import React, { useMemo } from 'react';
import rocket1 from '../../assets/etf-rocket1.svg';
import rocket2 from '../../assets/etf-rocket2.svg';
import rocket3 from '../../assets/etf-rocket3.svg';
import { AnimatedDiv, TAnimation } from '../animated-div';
import { HeroButton } from '../hero-button';
import { Parallax } from '../parallax';

// TODO How do we want to show single rocket on XS viewport?
const rockets = [
  {
    className:
      'self-center absolute z-[-1] top-[-230px] left-[150px] sm:left-[280px] md:left-[350px] lg:left-[400px]',
    src: rocket1,
    alt: 'rocket-1',
    speed: 2,
  },
  {
    className:
      'self-center absolute z-[-1] top-[70px] left-[250px] sm:left-[400px] md:left-[475px] lg:left-[550px]',
    src: rocket2,
    alt: 'rocket-2',
    speed: 6,
  },
  {
    className:
      'self-center absolute z-[-1] top-[-60px] left-[350px] sm:left-[525px] md:left-[600px] lg:left-[720px]',
    src: rocket3,
    alt: 'rocket-3',
    speed: 4,
  },
];

export const HomeHero: React.FC = () => {
  const rocketAnimation = useMemo((): TAnimation => {
    return {
      offscreen: {
        opacity: 0,
        y: 100,
      },
      onscreen: {
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.5, // Needs to happen after hero fades in to ensure smooth animation
          duration: 1.5,
          ease: 'easeOut',
        },
      },
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex flex-col relative z-10 w-full px-8 pt-14 gap-12 sm:px-8 sm:pt-24 sm:pb-0 sm:gap-14 lg:py-28 lg:px-20 xl:px-56">
        <div className="flex flex-row relative z-10">
          <div className="flex flex-col h-full gap-14 sm:gap-16">
            <div className=" text-white font-basisLight font-light text-left max-w-md h-auto text-4xl leading-[3rem] sm:max-w-lg sm:text-5xl sm:leading-[64px] md:text-6xl md:leading-[64px]">
              Your ETF, fueled by Goldman Sachs
            </div>
            <div className="flex text-gray-040 font-goldmanSansLight text-left h-auto text-lg leading-7 min-w-[300px] max-w-[390px] sm:max-w-none sm:w-[527px] sm:h-[108px] sm:text-2xl sm:leading-[36px]">
              Goldman Sachs ETF Accelerator is a digital platform that enables our clients to
              quickly and efficiently launch, list, and manage their ETFs.
            </div>
          </div>
          <div className="grid">
            {/* Each rocket will fade in and translate up into place */}
            {rockets.map(({ className, src, alt, speed }, index) => (
              <div key={index} className={className}>
                <Parallax speed={speed}>
                  <AnimatedDiv animation={rocketAnimation}>
                    <img className="max-w-none" src={src} alt={alt} />
                  </AnimatedDiv>
                </Parallax>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <HeroButton label={'Get in touch'} clickToScroll scrollToElementId={'get-in-touch'} />
        </div>
      </div>
    </div>
  );
};
