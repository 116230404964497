import { getCurrentEnv } from '@shadow/shadow-env-mapper';
import { initLogging, logWelcome } from '@shadow/shadow-logging-browser';
import { TLaunchScriptByEnv, UserAnalaytics } from '@shadow/shadow-user-analytics';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import './index.css';
import { injectGsdsFonts } from './utils/inject-gsds-fonts';

const env = getCurrentEnv();
const enableHttp = env !== 'LOCAL';

initLogging(enableHttp, {
  appId:
    env === 'PROD'
      ? '478b446d-09df-4577-8e14-26c1b822cc7e'
      : 'f2c2f8aa-a3cc-43cd-a78e-d0b21beaa07b',
  clientToken:
    env === 'PROD' ? 'pubaa48f1b3319e4acb506f4fe520e46982' : 'pubc43df09f0224010b054f5e69964442b3',
  env,
  logLevel: 'info',
  service: 'shadow-marketing-ui',
  trackInteractions: false,
  version: __SHADOW_PKG__.version,
});

interface IMarketingDataLayer {
  pageName: string;
}

type TMarketingAnalayticsEvent = 'event-page-load';

const launchScriptByEnv: TLaunchScriptByEnv = {
  PREVIEW: 'c594587281c3/b5b785e8905f/launch-86c631bfae5e-development.min.js',
  DEV: 'c594587281c3/b5b785e8905f/launch-86c631bfae5e-development.min.js',
  QA: '/adobe-assets/qa/c594587281c3/b5b785e8905f/launch-0fae28494f12-staging.min.js',
  PROD: '/adobe-assets/prod/c594587281c3/b5b785e8905f/launch-b10e07837eaf.min.js',
};

const analytics = new UserAnalaytics<IMarketingDataLayer, TMarketingAnalayticsEvent>(
  env,
  launchScriptByEnv
);
analytics.updateDataLayer({ pageName: 'Marketing Home' });
analytics.triggerEvent('event-page-load');

logWelcome(__SHADOW_PKG__);

injectGsdsFonts();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App env={env} />
  </React.StrictMode>
);
