import React from 'react';

interface ICardProps {
  children: React.ReactNode;
  className: string;
  title: string;
}

export const Card: React.FC<ICardProps> = ({ title, className, children }) => {
  return (
    <div
      className={
        'flex flex-col rounded-lg h-[280px] md:h-[360px] lg:h-[360px] 2xl:h-[460px] p-5 md:p-8 ' +
        className
      }
    >
      <div className="font-basis text-lg leading-8 md:text-2xl min-h-[64px]">{title}</div>
      <div className="flex self-center max-w-[80px] mt-[72px] mb-[44px] sm:max-w-[100px] sm:mt-[30px] sm:mb-[40px] md:max-w-[200px] md:mt-[60px] md:mb-[40px] lg:max-w-none lg:mx-[32px] lg:mt-[100px] lg:mb-[50px] xl:mx-[42px] xl:mt-[100px] xl:mb-[40px] 2xl:mt-[150px] 2xl:mb-[82px]">
        {children}
      </div>
    </div>
  );
};
