import { datadogLogs } from '@datadog/browser-logs';

export type TErrorStat = {
  ux: 'boundary-top' | 'boundary-local' | 'hero-alert';
  requestPath?: string;
};

type TLogMessage = (message: string, context?: object) => void;

export interface ILogger {
  debug: TLogMessage;
  error: TLogMessage;
  info: TLogMessage;
  warn: TLogMessage;
}

export const createLogger = (namespace: string): ILogger => {
  const logger = datadogLogs.logger;
  const commonContext = { ns: namespace };

  return {
    debug: (message: string, context = {}) => {
      logger.debug(message, { ...context, ...commonContext });
    },
    error: (message: string, context = {}) => {
      logger.error(message, { ...context, ...commonContext });
    },
    info: (message: string, context = {}) => {
      logger.info(message, { ...context, ...commonContext });
    },
    warn: (message: string, context = {}) => {
      logger.warn(message, { ...context, ...commonContext });
    },
  };
};
