import { motion, Variant } from 'framer-motion';
import React from 'react';

export type TAnimation = Record<string, Variant>;

export interface IAnimatedDivProps {
  animation: TAnimation;
  children: React.ReactNode;
  once?: boolean;
  viewportAmount?: number;
}

export const AnimatedDiv: React.FC<IAnimatedDivProps> = ({
  animation,
  children,
  once = false,
  viewportAmount,
}) => {
  return (
    <motion.div
      initial={'offscreen'}
      whileInView={'onscreen'}
      viewport={{ once: once, amount: viewportAmount }}
      variants={animation}
    >
      {children}
    </motion.div>
  );
};
