import React from 'react';
import cube1 from '../../assets/cube1.svg';
import cube2 from '../../assets/cube2.svg';
import cube3 from '../../assets/cube3.svg';
import { useMobileBreakpoint } from '../../hooks/use-mobile-breakpoint';
import { Cube } from '../cubes';
import { TextBlock } from '../text-block';

const cubes = [
  {
    className: 'flex justify-center mx-auto w-[150px] h-auto',
    src: cube1,
    alt: 'cube1',
  },
  {
    className: 'flex justify-center mx-auto w-[200px] h-auto',
    src: cube2,
    alt: 'cube2',
  },
  {
    className: 'flex justify-center mx-auto w-[350px] h-auto',
    src: cube3,
    alt: 'cube3',
  },
].map((curr, index) => <Cube key={index} {...curr} />);

export const TextBlockGrid: React.FC = () => {
  const mobile = useMobileBreakpoint();

  return (
    <div className="grid gap-28 lg:gap-24 relative z-10 w-full grid-cols-1 lg:grid-cols-3 pt-48 lg:pt-[310px] lg:px-20 xl:px-56">
      <div className="grid gap-20">
        {mobile && cubes[0]}
        <TextBlock title={'You'} subtitle={'provide the investment strategy and raise assets'} />
      </div>
      <div className="grid gap-20">
        {mobile && cubes[1]}
        <TextBlock title={'You'} subtitle={'maintain control of branding and marketing'} />
      </div>
      <div className="grid gap-20">
        {mobile && cubes[2]}
        <TextBlock title={'We'} subtitle={'do the heavy lifting'} />
      </div>
    </div>
  );
};
