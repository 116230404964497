import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react';

interface IParallaxProps {
  children: React.ReactNode;
  speed: number;
}

export const Parallax: React.FC<IParallaxProps> = ({ children, speed }) => {
  const { scrollYProgress } = useScroll();
  const yValue = useTransform(scrollYProgress, [0, 0.1, 1], [-10, -50 * speed, -100 * speed]);

  // Arrays adjusts parallax speed at certain percents of the y scroll of the div
  return <motion.div style={{ y: yValue }}>{children}</motion.div>;
};
