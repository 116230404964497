import React from 'react';
import homeHeroBackground from '../../assets/top-slice-bg-alt.webp';

export const HomeHeroBackground: React.FC = () => {
  return (
    <img
      className="self-center max-w-[1920px] absolute z-0 top-[3rem] sm:top-[7rem] md:top-[9rem] lg:top-[13rem]"
      src={homeHeroBackground}
      alt="home-hero-background"
    />
  );
};
