import React from 'react';
import rocketIcon from '../../assets/etf-rocket-bottom.svg';
import { FooterLink } from './footer-link';

export const Footer: React.FC<{}> = () => {
  return (
    <>
      <div className="flex justify-center items-center relative mb-[128px] mt-[8rem] mx-7 lg:mx-20 xl:mx-56">
        <img className="opacity-100 absolute z-0" src={rocketIcon} alt="rocket-icon" />
      </div>
      <div className="w-full px-8 lg:px-20 xl:px-56">
        <div className="text-blue-060 text-[10px] leading-normal w-full mb-4">
          <a
            href="https://www.goldmansachs.com/privacy-and-cookies/index.html"
            rel="noreferrer"
            target="_blank"
            data-track="aa"
          >
            Privacy Policy
          </a>
        </div>
        <div className="text-gray-060 text-[10px] leading-normal">
          <p className="mb-4">
            This site is for informational purposes only and does not constitute an offer to sell,
            or the solicitation of an offer to buy, any security. The GS ETF Accelerator™ platform
            is for U.S. registered investment adviser clients only and none of the platform services
            will result in Goldman Sachs becoming an affiliate, fiduciary, or investment adviser
            with respect to any fund, product, plan, or person that utilize, or are third-party
            beneficiaries of, such services. Some of the services and products described on this
            site may not be available in certain jurisdictions or to certain types of clients.
            Nothing on this site constitutes an offer, or an invitation to make an offer from
            Goldman Sachs to purchase or sell a product. This site is given for purely indicative
            purposes and does not create any contractual relationship between you and Goldman Sachs.
            Any market information contained on the site (including but not limited to pricing
            levels) is based on data available to Goldman Sachs at a given moment and may change
            from time to time. There is no representation that any transaction can or could have
            been affected on such terms or at such prices. Please see
            https://www.goldmansachs.com/disclaimer/sec-div-disclaimers-for-electronic-comms.html
            for additional information. © 2024 Goldman Sachs. All rights reserved.
          </p>
          <p className="mb-4">
            Goldman Sachs does not provide legal, tax, or regulatory advice. You should conduct
            adequate due diligence and discuss these services with your legal, tax, and regulatory
            professionals to make an independent determination of the suitability and consequences.
          </p>
          <p className="mb-4">
            Not all products and functionality mentioned on this website are currently available
            through our API platform.
          </p>
          <p className="mb-4">
            Brokerage services are provided by Goldman Sachs & Co. LLC (“GS&CO.”), which is an SEC
            registered broker-dealer and investment adviser, and member{' '}
            <FooterLink href="https://www.finra.org/#/">FINRA</FooterLink>/
            <FooterLink href="https://www.sipc.org/">SIPC</FooterLink>. Research our firm at FINRA's{' '}
            <FooterLink href="https://brokercheck.finra.org/">BrokerCheck</FooterLink>. Neither this
            site nor any of its contents shall constitute an offer, solicitation, or advice to buy
            or sell securities in any jurisdictions where GS&Co. is not registered. Any information
            provided prior to opening an investment account is on the basis that it will not
            constitute investment advice and that GS&Co. is not a fiduciary to any person by reason
            of providing such information.
          </p>
          <p className="mb-4">
            Investment products are: NOT FDIC INSURED ∙ NOT A DEPOSIT OR OTHER OBLIGATION OF, OR
            GUARANTEED BY, GOLDMAN SACHS BANK USA ∙ SUBJECT TO INVESTMENT RISKS, INCLUDING POSSIBLE
            LOSS OF THE PRINCIPAL AMOUNT INVESTED
          </p>
          <p>
            Conflict of Interest Disclosure: Goldman Sachs is a full-service, integrated investment
            banking, investment management, and brokerage firm. The professionals who work on GS ETF
            Accelerator are paid in part based on the profitability of The Goldman Sachs Group,
            Inc., which includes earnings from the firm’s trading, capital markets, investment
            banking and other businesses. They, along with other salespeople, traders, and other
            professionals may provide oral or written market commentary or trading strategies to our
            clients that reflect opinions that are contrary to the opinions expressed in research
            reports issued by our Research Departments, and our market making, investing, and
            lending businesses may make investment decisions that are inconsistent with GS ETF
            Accelerator. For additional information about our conflicts of interest when providing
            brokerage services, please see the PWM Relationship Guide available at www.gs.com/crsrg,
            the IBD Relationship Guide and any applicable account agreements and transaction
            disclosures or speak with your representative.
          </p>
        </div>
      </div>
    </>
  );
};
