import React, { createRef, useMemo } from 'react';
import { useHls } from './hls';

interface IVideoProps {
  poster: string;
  src: {
    hls: string;
    mp4: string;
  };
}

const isIOsDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

export const Video: React.FC<IVideoProps> = ({ poster, src }) => {
  const playerRef = createRef<HTMLVideoElement>();
  const { isHlsSupported } = useHls({
    hlsSrc: src.hls,
    playerRef,
  });

  const videoProps: React.VideoHTMLAttributes<HTMLVideoElement> = useMemo(
    () => ({
      autoPlay: false,
      className: 'w-full',
      controls: true,
      poster,
      width: 620,
    }),
    [poster]
  );

  if (!isHlsSupported() || isIOsDevice) {
    return <video data-track="aa" {...videoProps} src={src.mp4} />;
  }

  return <video data-track="aa" {...videoProps} ref={playerRef} />;
};
