import Hls from 'hls.js/dist/hls.min.js';
import { useEffect, useState } from 'react';

interface IUseHlsOptions {
  hlsSrc: string;
  playerRef: React.RefObject<HTMLVideoElement>;
}

export const useHls = (options: IUseHlsOptions) => {
  const { hlsSrc, playerRef } = options;
  const [hls, setHls] = useState<Hls | undefined>(undefined);

  useEffect(() => {
    const initPlayer = () => {
      if (playerRef.current !== null) {
        const hlsNew = new Hls({ enableWorker: false });

        hlsNew.attachMedia(playerRef.current);

        hlsNew.on(Hls.Events.MEDIA_ATTACHED, () => {
          hlsNew.loadSource(hlsSrc);
        });

        hlsNew.on(Hls.Events.ERROR, (_evt, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hlsNew.startLoad();
                break;
              case Hls.ErrorTypes.MEDIA_ERROR:
                hlsNew.recoverMediaError();
                break;
              default:
                initPlayer();
                break;
            }
          }
        });

        setHls(hlsNew);
      }
    };

    // wait until Video is on screen to start buffering
    if (Hls.isSupported() && hls === undefined) {
      initPlayer();
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [hls, hlsSrc, playerRef]);

  return {
    isHlsSupported: Hls.isSupported,
  };
};
