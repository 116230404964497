import React from 'react';
import { Footer } from '../components/footer';
import { GetInTouch } from '../components/get-in-touch';
import { NewsHero, NewsHeroBackground } from '../components/hero';
import { AllNewsTile, RecentNewsTile } from '../components/news-tile/news-tile';
import { newsItems } from '../data/news-items';

interface INewsProps {}

export const News: React.FC<INewsProps> = () => {
  return (
    <>
      <div className={'flex flex-col'}>
        <NewsHeroBackground />
      </div>
      <div className={'flex flex-col max-w-[1600px] w-full relative'}>
        <NewsHero />
      </div>
      <div className="flex justify-center w-full max-w-[1600px]">
        <div
          className={
            'flex flex-col self-center w-full max-w-[1162px] mx-8 2xl:mx-[14rem] xl:mx-[10rem] lg:mx-[5rem] text-white mb-16 lg:mb-[18rem] relative'
          }
        >
          <div className=" text-white font-basisLight text-left max-w-md h-auto 2xl:text-5xl 2xl:leading-[54px] xl:text-[40px] xl:leading-[46px] text-[28px] leading-[34px] sm:max-w-lg pb-6 lg:pt-2 pt-24">
            Recent News
          </div>
          <div className="grid lg:grid-cols-3 lg:grid-rows-2 gap-8 lg:min-w-[950px]">
            {newsItems.otherNews.slice(0, 6).map((newsItem) => (
              <RecentNewsTile data={newsItem} key={newsItem.headline} />
            ))}
          </div>
          <div className=" text-white font-basisLight text-left max-w-md h-auto 2xl:text-5xl 2xl:leading-[54px] xl:text-[40px] xl:leading-[46px] text-[28px] leading-[34px] sm:max-w-lg pb-6 lg:pt-48 pt-24">
            All News
          </div>
          <div className="flex flex-col">
            {newsItems.otherNews.slice(6).map((newsItem) => (
              <AllNewsTile data={newsItem} key={newsItem.headline} />
            ))}
          </div>
        </div>
      </div>
      <div className={'flex flex-col max-w-[1600px] mb-16 w-screen relative'}>
        <GetInTouch />
        <Footer />
      </div>
    </>
  );
};
