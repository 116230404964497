import React from 'react';
import { ISvgProps } from './types';

export const IconRegulatory: React.FC<ISvgProps> = ({ variant }) => {
  return variant === 'marketing-app' ? (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="64" cy="64" r="63" stroke="#39222C" strokeWidth="2" />
      <rect x="45" y="45" width="38" height="38" stroke="#39222C" strokeWidth="2" />
      <path d="M64.5 82.5V127.5" stroke="#39222C" strokeWidth="2" />
      <path d="M45 83L83 45" stroke="#39222C" strokeWidth="2" />
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 18.9863C15.238 18.7269 19 14.8028 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.8028 4.76201 18.7269 9.5 18.9863V14H6V6H14V14H10.5V18.9863ZM10.5 13H13V7.70712L7.70712 13H9.5H10.5ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM7 7H12.2929L7 12.2929V7Z"
        fill="#DD445D"
      />
    </svg>
  );
};
