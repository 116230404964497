import React from 'react';
import colorBackground from '../../assets/color-bg.svg';

export const CardGridBackground: React.FC = () => {
  return (
    <img
      className="self-center opacity-100 max-w-none w-screen absolute -z-50"
      src={colorBackground}
      alt="color-background"
    />
  );
};
