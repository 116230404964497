import React from 'react';
import newsBackground from '../../assets/news-background.png';

export const NewsHeroBackground: React.FC = () => {
  return (
    <>
      <img
        className="self-center w-full h-[900px] absolute z-0 top-0"
        src={newsBackground}
        alt="news-background"
      />
    </>
  );
};
