import React from 'react';

interface IQuoteProps {
  quoteText: string;
  name: string;
  title: string;
}

export const Quote: React.FC<IQuoteProps> = ({ quoteText, name, title }) => {
  return (
    <div className="flex flex-col relative z-10 w-full px-8 pt-28 sm:px-8 sm:pt-16 sm:pb-0 md:pt-[100px] lg:px-44 xl:px-80">
      <div className="text-gray-040 font-basisLight text-[32px] leading-[44px] md:text-[40px] md:leading-[56px]">
        “{quoteText}”
      </div>
      <div className="pt-[56px]">
        <div className="text-gray-040 font-goldmanSansMedium text-base leading-7">{name}</div>
        <div className="text-[#9fb1bd99] font-goldmanSansMedium text-base leading-7">{title}</div>
      </div>
    </div>
  );
};
