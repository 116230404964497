import { TShadowEnv } from '@shadow/shadow-env-mapper';
import { createLogger } from '@shadow/shadow-logging-browser';

export type TLaunchScriptURI = string;
export type TLaunchScriptByEnv = Partial<Record<TShadowEnv, TLaunchScriptURI>>;

type TWindowWithDataLayer<T> = typeof window & { adobeDataLayer: T };

const logger = createLogger('shadow-user-analytics');

export class UserAnalaytics<TDataLayer, TAnalyticsEvent extends string> {
  private initPromise?: Promise<void>;

  constructor(env: TShadowEnv, launchScriptByEnv: TLaunchScriptByEnv) {
    const script = launchScriptByEnv[env];

    if (!script) {
      logger.warn(
        `env [${env}] is not one of ${Object.keys(
          launchScriptByEnv
        )} so skipping analytics initialisation...`
      );

      return;
    }

    this.initPromise = new Promise<void>((resolve) => {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      const launchScript = launchScriptByEnv[env] as string;
      scriptElement.src = `${
        launchScript.startsWith('/') ? launchScript : `https://assets.adobedtm.com/${launchScript}`
      }?version=${Date.now()}`; // version param added to avoid using cached version
      logger.info(`Loading Analytics Launch script for env [${env}] from [${scriptElement.src}]`);

      document.body.appendChild(scriptElement);
      scriptElement.addEventListener('load', () => resolve());
    })
      .then(() => logger.info('Analytics Launch script successfully loaded!'))
      .catch((e) => logger.error(`Analytics Launch scipt failed to load: ${e}`));
  }

  /* All updates are merged with the current data layer. See https://github.com/adobe/adobe-client-data-layer/wiki.  */
  updateDataLayer = (data: TDataLayer) => {
    const windowWithDataLayer = window as TWindowWithDataLayer<TDataLayer[]>;
    if (!windowWithDataLayer.adobeDataLayer) {
      windowWithDataLayer.adobeDataLayer = [];
    }

    windowWithDataLayer.adobeDataLayer.push(data);
    logger.info(`Data layer updated: ${JSON.stringify(data)}`);
  };

  triggerEvent = (event: TAnalyticsEvent) => {
    this.initPromise
      ?.then(() => {
        document.dispatchEvent(new CustomEvent(event));
        logger.info(`Triggered analytics event: ${event}`);
      })
      .catch(() => {
        logger.warn(`Analytics initialization failed, so will not trigger event ${event}`);
      });
  };
}
