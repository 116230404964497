import React from 'react';
import { ISvgProps } from './types';

export const IconPortfolio: React.FC<ISvgProps> = ({ variant }) => {
  return variant === 'marketing-app' ? (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_142_1775"
        mask="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="128"
        height="128"
      >
        <rect width="128" height="128" fill="#DCE3E8" />
      </mask>
      <g mask="url(#mask0_142_1775)">
        <path
          d="M78.4669 1.77209L2.10441 78.1346C0.542313 79.6967 0.542315 82.2294 2.10441 83.7915L25.673 107.36"
          stroke="#193B4A"
          strokeWidth="2"
        />
        <path
          d="M52.0672 1.76923L78.4642 1.76922L78.4642 28.1662"
          stroke="#193B4A"
          strokeWidth="2"
        />
        <path
          d="M49.5338 126.221L125.896 49.8584C127.458 48.2963 127.458 45.7637 125.896 44.2016L102.328 20.633"
          stroke="#193B4A"
          strokeWidth="2"
        />
        <path d="M75.9335 126.224H49.5365V99.8268" stroke="#193B4A" strokeWidth="2" />
      </g>
    </svg>
  ) : (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.35768 14L3.20766 11.85L3.20723 11.8504L2.50012 11.1433L10.6434 3L8.35768 3L8.35768 2H11.3577H12.3577V3V6H11.3577L11.3577 3.69995L3.91476 11.1429L6.06479 13.2929L5.35768 14ZM15.5 6L17.65 8.15003L17.6505 8.1496L18.3576 8.85671L10.2143 17H12.5L12.5 18H9.50001H8.50001V17V14H9.50002L9.50001 16.3001L16.9429 8.85713L14.7929 6.70711L15.5 6Z"
        fill="#2DCBE3"
      />
    </svg>
  );
};
