import { TShadowEnv } from '@shadow/shadow-env-mapper';
import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { Navigation } from './components/navigation';
import { Home } from './pages/home';
import { News } from './pages/news';

interface IAppProps {
  env: TShadowEnv;
}

export const App: React.FC<IAppProps> = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <div
          id="nav"
          className={'flex flex-col max-w-[1600px] min-w-[300px] z-[11] w-screen relative'}
        >
          <Navigation />
        </div>
        <Routes>
          <Route path="/news" element={<News />} />
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<Navigate to={'/home'} replace={true} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
